import { useContext } from 'react'
import { ThemeContext } from '../context/ThemeProvider'
import { createGlobalStyle } from 'styled-components'
import colors from "./color"

// eslint-disable-next-line no-use-before-define
export const StyledGlobalStyle = createGlobalStyle`
* {

  ::-webkit-scrollbar:horizontal{
    display: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent !important;
    
  }

  ::-webkit-scrollbar-track {
  background-color: transparent !important;
}

  ::-webkit-scrollbar-thumb {
    background-color: rgb(99, 99, 99);
    border-radius: 16px;
    border: 1px solid #fff;
  }

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display:none;
}

    margin: 0;
    padding: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-family: 'Roboto';

  }

html, body {
    max-width: 100%;
    overflow-x: hidden;
    background: ${colors.TextBackground_0};
    }
`

function GlobalStyle() {

  const { theme } = useContext(ThemeContext)

  return (<StyledGlobalStyle isDarkMode={theme === 'dark'} />)

}

export default GlobalStyle
