import styled from 'styled-components'
import ReactPlayer from 'react-player'
import {useState, useRef, useEffect} from 'react'
import colors from "../../utils/style/color"

const GlobalDIV = styled.div`
    background-color: ${colors.TextBackground_4};
    padding-top: 1rem;
    padding-bottom: 4rem;
`

// text gestation header
const AnchorTextH1 = styled.h1`

    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    color: white;

    @media(max-width: 904px) {
        font-size: 2rem;
}

`
const TextDIV = styled.div`
    
    padding: 3rem 0;
    @media(max-width: 904px) {
        padding: 3rem 0;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

`
const LineDIV = styled.div`
    display:inline-block;
    vertical-align: middle;
    height:2px;
    width: ${(props) => props.$widthE + "px"};
    background: ${colors.skincolorComplementary};
    content:"";
    margin :0.25rem;

    @media(max-width: 904px) {
        width: 100vw;
}
`

// react player
const FlexContainerDIV = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 904px){
        flex-direction: column;
    }
`

const ReactPlayerWrapper = styled.div`
    position: relative;
    width: 25vw;
    height: 70vh;
    border-radius: 30px;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: ${colors.TextBackground_2};

    @media (max-width: 904px){
        width: 70vw;
        height: 70vh;
        margin-bottom: 2rem;
    }

    .react-player{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;

    .img {border-radius:10px}
    }

`

// play logo gestation
const PlayDiv = styled.div`
    position:absolute;
    background-image: url('/data/home/video/PlayLogo.webp');
    width: 20%;
    height: 20%;
    top:40%;
    right:40%;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    transition: 0.5s all ease-in-out;
    opacity:  ${(props) => props.$videoIsPlaying ?  '0' : '1'};

    &:hover{
        cursor: ${(props) => props.$videoIsPlaying ?  'auto' : 'pointer'};
    }
`

function VideoPresentation() {

    const ref = useRef(null);
    const [widthE, setWidth] = useState(0);

    let gofalseMobile = true
    if(window.innerWidth <= 700){
        gofalseMobile = false
    }
    

    // resize line gestation
    useEffect(() => {
        // when the component gets mounted
        setWidth(ref.current.offsetWidth);
        // to handle page resize
        const getwidth = () => {
          setWidth(ref.current.offsetWidth);
        };
        window.addEventListener("resize", getwidth);
        // remove the event listener before the component gets unmounted
        return () => window.removeEventListener("resize", getwidth);
      }, []);

    // video player logo control
    const [videoGoPlay, setvideoGoPlay] = useState([false,gofalseMobile,false]); 

    function videoPlayGestation(numberVideo) {

        if (numberVideo === 1) {
            setvideoGoPlay([true,false,false])
        }

        else if (numberVideo === 2) {
            setvideoGoPlay([false,true,false])
        }

        else {
            setvideoGoPlay([false,false,true])
        }

        
    }

    return <GlobalDIV>

        <TextDIV>
            <div data-aos="fade-right" data-aos-duration = "800" data-aos-offset="30">
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <LineDIV $widthE = {widthE}></LineDIV>
                    <AnchorTextH1 ref={ref}>DÉCOUVERTE EN VIDÉO</AnchorTextH1>
                    <LineDIV style={{marginBottom:"1rem"}} $widthE= {widthE}></LineDIV>
                </div>
                <p style={{color: "white", textAlign: "center", fontSize: "1.5rem", padding:"0 2rem 1rem 2rem"}}><b>Hygiène rigoureuse et art personnalisé</b></p>
            </div>
        </TextDIV>

        <FlexContainerDIV>

        <ReactPlayerWrapper>
                <PlayDiv className="style-2" $right={1} $videoIsPlaying = {videoGoPlay[1]} onClick={() => videoPlayGestation(2)}></PlayDiv>
                <ReactPlayer 
                    playing={videoGoPlay[1]}
                    loop={true} 
                    url={'/data/home/video/videoPres2.mp4'} 
                    width="100%" 
                    height="100%" 
                    className = "react-player"
                    muted = {true}
                    >
                </ReactPlayer>
            </ReactPlayerWrapper>

            <ReactPlayerWrapper>
                <PlayDiv className="style-3" $right={2} $videoIsPlaying = {videoGoPlay[2]} onClick={() => videoPlayGestation(3)}></PlayDiv>
                <ReactPlayer 
                    playing={videoGoPlay[2]}
                    loop={true} 
                    url={'/data/home/video/videoPres3.mp4'} 
                    width="100%" 
                    height="100%"
                    className = "react-player"
                    muted = {true}
                    >
                </ReactPlayer>
            </ReactPlayerWrapper>


            <ReactPlayerWrapper>
                <PlayDiv className="style-1" $videoIsPlaying = {videoGoPlay[0]} onClick={() => videoPlayGestation(1)}></PlayDiv>
                <ReactPlayer 
                    playing={videoGoPlay[0]}
                    loop={true} 
                    url={'/data/home/video/videoPres1.mp4'} 
                    width="100%" 
                    height="100%" 
                    className = "react-player"
                    muted = {true}
                    >
                    </ReactPlayer>
            </ReactPlayerWrapper>


        </FlexContainerDIV>

        </GlobalDIV>

}

export default VideoPresentation