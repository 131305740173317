import styled from 'styled-components'
import colors from '../../utils/style/color'
import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { useNavigate } from "react-router-dom";

    const ImageGalleryUL = styled.ul`
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        list-style-type: none;

        & li { flex-basis:350px;
            flex: 1 1 auto;
            height:${(props) => props.$sizeModif ?  '65vh' : '80vh'};
            width: ${(props) => props.$sizeModif ?  '65vh' : 'auto'};
            position:relative; 
            cursor: pointer;

            @media (max-width: 904px){
                height:${(props) => props.$sizeModif ?  '50vh' : '45vh'};
                width: ${(props) => props.$sizeModif ?  '50vh' : 'auto'};
            }

            /* iPad (all models) in landscape mode */
            @media (min-width: 790px) and (max-width: 1100px) and (min-height: 790px) and (max-height: 1100px){
                height:${(props) => props.$sizeModif ?  '70vh' : '75vh'};
                width: ${(props) => props.$sizeModif ?  '50vh' : 'auto'};
            }

            &:hover{
                & div {transform: ${(props) => props.$enableOverllay ?  'scale(1)' : 'scale(0)'};}
                
            }
        }

        &::after{
            content: "";
            flex-grow: 999;
        }

    `

    const ImageGalleryIMG = styled.img`
        object-fit: cover;
        height: 100%;
        width: 100%;
        vertical-align: middle;
        border-radius: 20px;
        background-color: ${(props) => props.$withBackgroundColor ?  colors.FlashBackgroundColor : 'none'};
    `

    const OverllayImageGalleryDIV = styled.div`
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(57, 57, 57, 0.502);
        top: 0;
        left: 0;
        transform: scale(0);
        transition: all 0.2s ease-in-out;
        color: #fff;
        border-radius: 20px;
        /* center overlay text */
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-align: center;

        @media (max-width: 904px), (max-height: 504px){
            transform: ${(props) => props.$enableOverllay ?  'scale(1)' : 'scale(0)'};
            background: none;
                }
        /* iPad (all models) in landscape mode */
        @media (min-width: 790px) and (max-width: 1100px) and (min-height: 790px) and (max-height: 1100px){
            transform: ${(props) => props.$enableOverllay ?  'scale(1)' : 'scale(0)'};
            background: none;
        }
    `

    const Span2 = styled.span`
        position: absolute;
        top:0rem;
        right: 0rem;
        font-size: 0.75rem;
        margin: 0.5rem;
        padding: 0.5rem;
        border-radius: 10px;
        background:  ${(props) => (props.$categorie === 'Noir & Couleur') ?  "linear-gradient(to right,rgb(172, 136, 108), rgb(106, 134, 170))" : "linear-gradient(to right,rgb(43, 43, 43), rgb(0, 0, 0))"};

            /* iPad (all models) in landscape mode */
        @media (min-width: 700px) and (max-width: 900px) and (min-height: 300px) and (max-height: 450px){
            font-size: 0.45rem;
        }
    `


function ImageGallery({clickGoForm, sizeModif, enableViewer, enableOverllay, rootData, DataImgGalery, withBackgroundColor}) {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    let imagessrcArray = [];
    DataImgGalery.map(function(data) {
        return (

            imagessrcArray.push('/data/'+rootData+'/image/'+data.path)

                )
    })

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
    
      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };

    let navigate = useNavigate();

    function goForm(clickGoForm, flashPath, categorie){
        if(clickGoForm){
        let path = "/contact/flash"; 
        navigate(path, { state: { flashPath: flashPath, categorie: categorie } });
    }}

    return (
        <div style={{backgroundColor:colors.TextBackground_1, padding:"3rem 1rem"}}> 
        
            <ImageGalleryUL $sizeModif={sizeModif} $enableOverllay={enableOverllay}>

            {DataImgGalery.map(function(data) {
                return (
                    
                    <li key={data.id} onClick={() => {goForm(clickGoForm, data.path, data.categorie)}}>
                        <ImageGalleryIMG src={'/data/'+rootData+'/image/'+data.path} $withBackgroundColor={withBackgroundColor} alt="" onClick={ () => {if(enableViewer){ openImageViewer(data.id-1)}}}/>
                        <OverllayImageGalleryDIV $enableOverllay={enableOverllay}>
                            <Span2 $categorie={data.categorie}>Disponible en {data.categorie}</Span2>
                        </OverllayImageGalleryDIV>
                    </li>
                        )
            })}

            {isViewerOpen && (
                    <ImageViewer
                    src={ imagessrcArray }
                    currentIndex={ currentImage }
                    disableScroll={ false }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                    />
                )}
                
            </ImageGalleryUL>

        </div>
)}

export default ImageGallery
 