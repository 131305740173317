import styled from 'styled-components'
import {useState, useRef, useEffect} from 'react'
import { useInView } from 'react-intersection-observer';
import { NavLink } from 'react-router-dom'
import colors from "../../utils/style/color"

// text gestation header
const AnchorTextH1 = styled.h1`

    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    color: white;

    @media(max-width: 904px) {
        font-size: 2rem;
}

`
const TextDIV = styled.div`
    
    padding: 3rem 0;
    overflow-x: hidden;
    @media(max-width: 904px) {
        padding: 3rem 0;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

`
const LineDIV = styled.div`
    display:inline-block;
    vertical-align: middle;
    height:2px;
    width: ${(props) => props.$widthE + "px"};
    background: ${colors.skincolorComplementary};
    content:"";
    margin :0.25rem;

    @media(max-width: 904px) {
        width: 100vw;
}
`

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;

    @media(max-width: 904px) {
        flex-direction: column;
}
@media(min-width: 904px) and (max-width : 1200px) and (min-height: 904px) and (max-height : 1200px)   {
    flex-direction: column;
        }
`
    // Navlink x 3
    const SpecialFilterLinkGlobalBUTTON = styled(NavLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: ${colors.skincolorComplementary};
    border-radius: 20px;
    margin-bottom: 2rem;
    cursor: pointer;
    width: 30%;
    height: 70vh;
    margin: 1rem;

    @media(max-width: 904px) {
        width: 90%;
        min-height: 30vh;
        max-height: 40vh;

    }

        @media(min-width: 904px) and (max-width : 1200px) and (min-height: 904px) and (max-height : 1200px)   {
            width: 100%;
            min-height: 30vh;
            max-height: 50vh;
        }

    `

    // Navlink overlapp
    const SpecialFilterLinkSecondBUTTON = styled(SpecialFilterLinkGlobalBUTTON)`
    background-color: white;
    `
    const SpecialFilterLinkThreelBUTTON = styled(SpecialFilterLinkGlobalBUTTON)`
    background-color: ${colors.skincolor};
    `

        // Image inside Navlink
        const PresentationFirstIMG = styled.img`
            display: flex;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            object-fit: cover;

            @media(min-width: 904px) {
                opacity: ${({$isActiveGlobal}) => $isActiveGlobal ? '0.8' : '1'};
                transition: all 0.2s ease-in-out;
            }

            @media(max-width: 904px) {
                opacity: ${({$isObserverSlide}) => $isObserverSlide ? '0.8' : '1'};
                transition: all 0.5s ease-in-out;
}

        `
        // text inside Navlink
        const PresentationtexteH1 = styled.h1`
            position: absolute;
            text-align: center;
            color: white;
            transition:  all 0.2s ease-in-out;
            font-size: ${({ $isActiveGlobal}) => $isActiveGlobal ? '2rem' : '1.5rem'};

            @media(max-width: 904px) {
            font-size: ${({ $isActiveGlobal}) => $isActiveGlobal ? '2rem' : '1rem'};
            font-size: ${({ $isObserverSlide}) => $isObserverSlide ? '2rem' : '1rem'};
            
            }

        `

const SecondPresentation = () => {
    
    const ref = useRef(null);
    const [widthE, setWidth] = useState(0);

    // mobile gestation with schroll
    let { ref: refOneSlide, inView:isObserverOneSlide } = useInView({threshold: [0.4,0.6]});
    let { ref: refTwoSlide, inView:isObserverTwoSlide } = useInView({threshold: [0.4,0.6]});
    let { ref: refThreeSlide, inView:isObserverThreeSlide } = useInView({threshold: [0.4,0.6]});

    useEffect(() => {
        // when the component gets mounted
        setWidth(ref.current.offsetWidth);

        // to handle page resize
        const getwidth = () => {
          setWidth(ref.current.offsetWidth);
        };
        window.addEventListener("resize", getwidth);
        // remove the event listener before the component gets unmounted
        return () => window.removeEventListener("resize", getwidth);
      }, []);

    const [isActiveOne, setActiveOne] = useState(false);
    const [isActiveTwo, setActiveTwo] = useState(false);
    const [isActiveThree, setActiveThree] = useState(false);

    return (
        <div style={{backgroundColor: colors.TextBackground_3, padding:"1rem 0"}}>

        <TextDIV>
            <div data-aos="fade-left" data-aos-duration = "800" data-aos-offset="30">
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <LineDIV $widthE = {widthE}></LineDIV>
                    <AnchorTextH1 ref={ref}>FLASH & CRÉATION PERSONNALISÉE</AnchorTextH1>
                    <LineDIV style={{marginBottom:"1rem"}} $widthE= {widthE}></LineDIV>
                </div>
                <p style={{color: "white", textAlign: "center", fontSize: "1.5rem", padding:"0 2rem 0 2rem"}}><b>Contactez-moi pour obtenir un devis gratuit</b></p>
            </div>
        </TextDIV>
        

        <FlexContainerDIV>

            <SpecialFilterLinkGlobalBUTTON ref={refOneSlide} onMouseEnter={()=>setActiveOne(!isActiveOne)} onMouseLeave={()=>setActiveOne(!isActiveOne)} to="/flash/">
                    <PresentationFirstIMG $isObserverSlide={isObserverOneSlide} $isActiveGlobal={isActiveOne} src={'/data/home/flash.webp'} alt="buttonpng"/>
                    <PresentationtexteH1 $isObserverSlide={isObserverOneSlide}  key={"global1txt"} $isActiveGlobal={isActiveOne}>Flash</PresentationtexteH1>
            </SpecialFilterLinkGlobalBUTTON>

            <SpecialFilterLinkSecondBUTTON ref={refTwoSlide} onMouseEnter={()=>setActiveTwo(!isActiveTwo)} onMouseLeave={()=>setActiveTwo(!isActiveTwo)} to="/projetPerso/">
                    <PresentationFirstIMG $isObserverSlide={isObserverTwoSlide} $isActiveGlobal={isActiveTwo}  src={'/data/home/projetPerso.webp'} alt="buttonpng"/>
                    <PresentationtexteH1 $isObserverSlide={isObserverTwoSlide}  key={"global2txt"} $isActiveGlobal={isActiveTwo}>Réalisations</PresentationtexteH1>
            </SpecialFilterLinkSecondBUTTON>

            <SpecialFilterLinkThreelBUTTON ref={refThreeSlide} onMouseEnter={()=>setActiveThree(!isActiveThree)} onMouseLeave={()=>setActiveThree(!isActiveThree)}  to="/Contact/">
                    <PresentationFirstIMG $isObserverSlide={isObserverThreeSlide}$isActiveGlobal={isActiveThree}  src={'/data/home/contact.webp'} alt="buttonpng"/>
                    <PresentationtexteH1 $isObserverSlide={isObserverThreeSlide}  key={"global3txt"} $isActiveGlobal={isActiveThree}>Contact</PresentationtexteH1>
            </SpecialFilterLinkThreelBUTTON>

        </FlexContainerDIV>

        </div>


    )
  }
  
  export default SecondPresentation;