import FirstPresentation from "../components/home/FirstPresentation"
import styled from 'styled-components'
import colors from "../utils/style/color"

const DivMentions = styled.div`
    display: flex;
    flex-direction: column;
    text-align: justify;
    color: white;
    background-color: ${colors.TextBackground};
    padding: 2rem;
`

const P = styled.p`
    margin: 0.5rem;

`

function MentionsLegales() {

    return (

        <div>
        
            <FirstPresentation bigText={true} presentationImgPath={"/data/home/banner/mentions.webp"} text={<div><em> Mentions légales InkByNoux</em></div>}></FirstPresentation>

            <DivMentions>
                <P>Raison sociale: <b>M. DONDAINE BASTIEN</b></P>
                <P>Capital social : <b>Auto-entrepreneur</b></P>
                <P>Adresse : <b>Montpellier</b></P>
                <P>Adresse électronique : <b>inkbynoux@gmail.com</b></P>
                <P>Téléphone : <b>07 78 17 48 54</b></P>
                <P>SIRET : <b></b></P>
                <P>Nom du directeur de la rédaction/publication : <b>Sébastien Petit</b></P>
                <br></br>
                <br></br>
                <P>Ce site a été conçu et réalisé par <b>Sébastien Petit - SebaDev</b></P>
                <br></br>
                <P>L'hébergement du site ainsi que le stockage des données personnelles sont assurés par la société Netlify Inc., dont le siège social est situé au 2325 3rd Street, Suite 215, San Francisco, California 94107 aux Etats-Unis : <a href="https://www.netlify.com/">https://www.netlify.com/</a></P>

            </DivMentions>

        </div>
    )
}
 
export default MentionsLegales