export const flashList_noir = [
	{
		text: 'Noir',
		value: 'Noir',
		id: '1Noir',
	},
]

export const flashList_couleur = [
	{
		text: 'Rouge',
		value: 'Rouge',
		id: '1Rouge',
	},
	{
		text: 'Noir',
		value: 'Noir',
		id: '2Noir',
	},
    {
		text: 'Bleu',
		value: 'Bleu',
		id: '3Bleu',
	},
    {
		text: 'Vert',
		value: 'Vert',
		id: '4Vert',
	},
    {
		text: 'Orange',
		value: 'Orange',
		id: '5Orange',
	},
    {
		text: 'Jaune',
		value: 'Jaune',
		id: '6Jaune',
	},
    {
		text: 'Rose',
		value: 'Rose',
		id: '7Rose',
	},
    {
		text: 'Violet',
		value: 'Violet',
		id: '8Violet',
	},
    {
		text: 'Blanc',
		value: 'Blanc',
		id: '9Blanc',
	},

]

export const flashList_2couleur = [
	{
		text: 'Rouge & Orange',
		value: 'Rouge & Orange',
		id: '1Rouge & Orange',
	},
	{
		text: 'Bleu & Violet',
		value: 'Bleu & Violet',
		id: '2Bleu & Violet',
	},
    {
		text: 'Vert & Jaune',
		value: 'Vert & Jaune',
		id: '3Vert & Jaune',
	},
    {
		text: 'Violet & Rose',
		value: 'Violet & Rose',
		id: '4Violet & Rose',
	},
]

export const flashList_3couleur = [
    {
        text: 'Rouge, Orange & Jaune',
        value: 'Rouge, Orange & Jaune',
        id: '1Rouge, Orange & Jaune',
    },


]