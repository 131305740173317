export const realisationBest = [
	{
		path: '1.webp',
		id: '1',
	},
	{
		path: '2.webp',
		id: '2',
	},
	{
		path: '3.webp',
		id: '3',
	},
	{
		path: '4.webp',
		id: '4',
	},
	{
		path: '5.webp',
		id: '5',
	},
	{
		path: '6.webp',
		id: '6',
	},
	{
		path: '7.webp',
		id: '7',
	},
	{
		path: '8.webp',
		id: '8',
	},
	{
		path: '9.webp',
		id: '9',
	},
	{
		path: '10.webp',
		id: '10',
	}
]