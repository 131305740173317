import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import colors from "../../utils/style/color"
import Burger from './Hamburger'
import {useState, useRef} from 'react'
import { ClickOutside } from '../../utils/hooks/ClickOutside';

// Logo link
const StyledLogoNAVLINK = styled(NavLink)`
    padding-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.25s;

    &:hover{
        transform: scale(1.05);
    }

    @media (max-width: 904px){
        padding-left: 0;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

`

// Logo imgage 
const StyleLogoIMG = styled.img`
    height: 30px; 
    width: 157px; // x 5.25   


`

const HeaderContainer = styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    top:0;
    width: 100vw;
    z-index: 2;

    @media (max-width: 904px){
        transform: ${(props) => ( (!props.open) ? "translateY(-100%)" : "translateY(0)")};
        transition: all 1s;
    }


`

// flex container global
const HeaderGlobalStyleNAV = styled.nav`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    margin: 2.5vh 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 95vw;
    border-radius: 15px;
    background-color: ${colors.blackFilter_6};
    backdrop-filter: blur(5px);

    &:hover{
        border: 1px solid;
        border-color: #8f8f8f;
    }
    

    @media (max-width: 904px){
        position: relative;
        flex-direction: column;
    }
`

    const StyledNavLinkDIV = styled.div`
        padding-left: 10rem;
        
        @media (max-width: 1150px){
            padding-left: 1rem;
            }

        @media (max-width: 904px){
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 0 0;

            }
    `

    // Link of navbar
    const StyledNAVLINK = styled(NavLink)`
        text-decoration: none;
        color: white;
        margin-left: 2rem;
        font-size: 1rem;

        &:hover{
            color: ${colors.skincolor};
        }

        &.${props => props.$activeclassName} {
            color: ${colors.skincolor};
        }

        @media (max-width: 904px){
                margin-top: 0.75rem;
                margin-bottom: 0.75rem;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                margin-left: 0rem;
                width: 95vw;
                background-color: ${colors.blackFilter_4};
            }
    `

const DivInstaLink = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    @media (max-width: 904px){
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

`

const AInsta = styled.a`

    position: relative;
    align-items: flex-end;
    margin-right: 1rem;
    padding-right: 2rem;
    color: white;
    transition : color 0.2s;

    @media (max-width: 904px){
        padding-right: 0;
        margin-right:0;
        align-items: center;
        color:#E64763;

    }

&:hover{
    color:#E64763;
}

`

function Header() {

    const [open, setActive] = useState(false);

    const node = useRef();

    const wrapperRef = useRef();
    ClickOutside(wrapperRef, () => setActive(false));


    function goTop(){
        window.scrollTo({  top: 0,
            left: 0,
            behavior: 'smooth'});
    }
    
    return (

        <div ref={wrapperRef}>

        <Burger open={open} setActive={setActive}/>

        <HeaderContainer open={open}>

            <HeaderGlobalStyleNAV ref={node}>

                <StyledLogoNAVLINK to="/"><StyleLogoIMG src={'/data/header/logo.webp'} alt='logo'/></StyledLogoNAVLINK>
                
                
                <StyledNavLinkDIV open={open}>
                    <StyledNAVLINK onClick={() => setActive(!open) & goTop()} $activeclassName="active" open={open} to="/"><b>Accueil</b></StyledNAVLINK>
                    <StyledNAVLINK onClick={() => setActive(!open) & goTop()} $activeclassName="active" open={open} to="/flash/"><b>Flash</b></StyledNAVLINK>
                    <StyledNAVLINK onClick={() => setActive(!open) & goTop()} $activeclassName="active" open={open} to="/projetPerso/"><b>Réalisations</b></StyledNAVLINK>
                    <StyledNAVLINK onClick={() => setActive(!open) & goTop()} $activeclassName="active" open={open} to="/info/"><b>Hygiène & Soins</b></StyledNAVLINK>
                    <StyledNAVLINK onClick={() => setActive(!open) & goTop()} $activeclassName="active" open={open} to="/contact/"><b>Contact</b></StyledNAVLINK>
                </StyledNavLinkDIV>

                <DivInstaLink>
                    <AInsta href="https://www.instagram.com/noux.tt/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram fa-2x"></i> </AInsta>
                </DivInstaLink>

            </HeaderGlobalStyleNAV>
                


        </HeaderContainer>

        </div>

    )
}

export default Header