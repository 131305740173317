import styled from 'styled-components'
import colors from "../../utils/style/color"
import "../../utils/style/arrowAnimation.css"


// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: ${(props) => props.$backgroundcolor};
`

const ArrowDIV = styled.div`
    position: relative;
    cursor: pointer;

    & span {
        display: block;
        width: 1rem;
        height: 1rem;
        border-bottom: 0.2rem solid;
        border-right: 0.2rem solid;
        border-color: ${(props) => props.$arrowsColor};
        transform: rotate(45deg);
        margin: -10px;
        animation: animate 2s infinite;
        
        &:nth-child(2) {
            animation-delay: -0.2s;
        }

        &:nth-child(3) {
            animation-delay: -0.4s;
        }

        }
    
        @keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
`

const ArrowAnimation = ({text, backgroundcolor, textEnable}) => {



    return (

        <FlexContainerDIV $backgroundcolor={backgroundcolor}>

            {(textEnable) &&
                <div>
                    <p style={{color: "white", textAlign: "center", fontSize: "1.75rem",letterSpacing:"0.15rem", padding:"2rem 2rem 2rem 2rem"}}><b>{text}</b></p>
                </div>
            }
                <ArrowDIV style={{padding:"0.5rem 0 1rem 0"}} $arrowsColor = {colors.skincolor}>
                    <span></span>
                    <span></span>
                    <span></span>
                </ArrowDIV>


        </FlexContainerDIV>

    )
  }
  
export default ArrowAnimation;