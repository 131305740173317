import FirstPresentation from "../components/home/FirstPresentation"
import SecondPresentation from "../components/home/SecondPresentation"
import ChoicePresentation from "../components/home/ChoicePresentation"
import VideoPresentation from "../components/home/VideoPresentation"
import RealisationsBestSlider from "../components/home/RealisationsBestSlider"
import RealisationBestSliderMobile from "../components/home/RealisationBestSliderMobile"
import BlockPresentationIcon from "../components/home/BlockPresentationIcon"
import { StyleSheetManager } from 'styled-components'
import {realisationBest} from "../data/realisationBest"
import {useEffect, useState} from 'react'

import { useContext } from 'react'
import { ThemeContext } from '../utils/context/ThemeProvider'

function Home() {

    const {IsSpecialSocialBrowser } = useContext(ThemeContext)

    console.log(IsSpecialSocialBrowser)

    const [isMobileVersion, setIsMobileVersion] = useState(window.innerWidth < 904);

    useEffect(() => {

    if(window.innerWidth <= 904){
        setIsMobileVersion(true)
    }

    else{ setIsMobileVersion(false)}

    }, []);

    return <div>

    {/* prop pass to styled component not direct to DOM */}
    <StyleSheetManager shouldForwardProp={() => true} disableVendorPrefixes={false}>

        <FirstPresentation contactbutton={true} bigText={false} presentationImgPath={'/data/home/banner/home.webp'} text={<div><em>INK BY NOUX TATOO</em></div>} text1={"Tatoueur sur Montpellier"}/>

         <SecondPresentation/>

         <BlockPresentationIcon/>

        {(isMobileVersion || IsSpecialSocialBrowser) &&
        (<RealisationBestSliderMobile rootData='home' dataImgGalery={realisationBest}/>)
        }

        {(!isMobileVersion && !IsSpecialSocialBrowser) &&
        (<RealisationsBestSlider rootData='home' dataImgGalery={realisationBest}/>)
        }

        <ChoicePresentation/>

        <VideoPresentation/>

    </StyleSheetManager>

        </div>

}

export default Home
