import styled from 'styled-components'
import BlockIcon from "./BlockIcon"

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    padding: 2rem 0rem;
    
`
// use aos package
const BlockPresentationIconTatoo = () => {

    return (

        <FlexContainerDIV>

            <BlockIcon needresize={false} key={"1x"} imgPath={'/data/home/machine.webp'} title={"Préparation"} text={"Validation de couleur, taille et forme avant tatouage"}></BlockIcon>
            <BlockIcon needresize={true}  key={"2x"} imgPath={'/data/home/tatoo.webp'} title={"Personnalisation"} text={"Tatouage flash unique ou création personnalisée pour chaque silhouette"}></BlockIcon>
            <BlockIcon needresize={false}  key={"3x"} imgPath={'/data/home/hygiene.webp'} title={"Prévention"} text={"Hygiène stricte et respect des normes avant et après tatouage"}></BlockIcon>

            
        </FlexContainerDIV>

    )
  }
  
  export default BlockPresentationIconTatoo;