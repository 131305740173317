import styled from 'styled-components'
import colors from "../../utils/style/color"

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    margin:1.5rem 1rem;
    min-width: 15vw;
    min-height: 10vh;
    flex-wrap: wrap; /* Allow items to wrap to the next column */
`

const BlockContainerLogo = styled.a`
    display: flex;
    background-color: ${colors.TextBackground_4bis};
    border-radius:50%;
    cursor:pointer;
    transition: all 0.3s ease-in-out;

    &:hover{
        background-color: ${colors.skincolor}
    }

`

const Img = styled.img`
    position:relative;
    width: 20px;
    height: 20px;
    margin:1rem;

    @media (min-width: 1500px){
        width: 35px;
        height: 35px;
    }

`

const H1 = styled.h1`
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
    max-width: 100%; // Add this line

    @media (min-width: 1500px){
        font-size: 1rem;
    }
`
  
const ContactBlockIcon = ({imgPath, text, link}) => {


    return (

        <FlexContainerDIV>

                    <BlockContainerLogo href={link} target="_blank">
                        <Img src={imgPath} alt="images contact, horaire, mail,tel...etc"></Img>
                    </BlockContainerLogo>

                    <H1>{text}</H1>

        </FlexContainerDIV>

    )
  }
  
export default ContactBlockIcon;