import styled from 'styled-components'
import colors from "../../utils/style/color"
import { v4 as uuidv4 } from 'uuid';

// Global flex container form
const ContainerFormDIV = styled.div`
    margin: 2rem;
    display: flex;
    background: ${colors.TextBackground};
    flex-direction: column;
    padding: 2.5rem;
    border-radius: 10px;
    border: solid;
    border-color: #BE8A66;
    
    
`

const H1 = styled.h1`
    color: white; 
    text-align: center; 
    font-size: 2.5rem;
    margin-bottom: 1rem;
`

const LI = styled.li`
    color: white;
    margin: 0.5rem;
    font-size: 1rem;

`


function Protocole({HeaderTextContainer, BubbleListData}) {

    return (

            <ContainerFormDIV>

                <H1>{HeaderTextContainer}</H1>

                    {BubbleListData.map(data =>(
                        <LI key={uuidv4()}>{data}</LI>
                    ))}

            </ContainerFormDIV>
        
)}

export default Protocole