import FirstPresentation from "../components/home/FirstPresentation"
import ArrowAnimation from "../components/flashAndPersonalProject/ArrowAnimation"
import ImagesGallery from "../components/flashAndPersonalProject/ImagesGallery"
import {personalProject} from "../data/personalProject"
import colors from "../utils/style/color"

function PersonalProject() {

    return <div> 

        <FirstPresentation contactbutton={true} bigText={false} presentationImgPath={'/data/home/banner/galerie.webp'} text={<div><em>GALERIE PHOTO</em></div>}  text1={"Découvre mes réalisations"}> </FirstPresentation>
        
        <ArrowAnimation text = "GALERIE DES TATOUAGES" backgroundcolor={colors.TextBackground_1} textEnable={true}></ArrowAnimation>

        <ImagesGallery clickGoForm={false} sizeModif={false} enableViewer={true} enableOverllay={false} rootData='galerie' DataImgGalery={personalProject} withBackgroundColor={false} ></ImagesGallery>
        
        </div>
}

export default PersonalProject
