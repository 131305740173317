import styled from 'styled-components'
import React from 'react'
import { NavLink } from 'react-router-dom'
import colors from "../../utils/style/color"

// Global flex container
const FlexContainerFooterDiv = styled.div`
    background-color: ${colors.TextBackground_0};
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
`

// container with practical information, logo and social media link
const FooterLinksDIV = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 0.5rem;

`
// flexbox to contain practical information, logo and social media link
const FooterLinksDIVDIV = styled.div`
    width: 190px;
    margin:1rem;
    padding-left:5rem;
    padding-right:5rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;    

    & a { color: rgb(175,175,179);text-decoration: none; }

    & p { font-size: 0.8rem; line-height:15px;margin:0.3rem 0.75rem;cursor:pointeur; }

    @media (max-width: 904px){
                margin: 1rem 0rem;
            }

`

const FooterTxtAndLogoDIV = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    & img {height:2em ; width:2em}

    & p {cursor: pointer;

        &:hover{
            color:${colors.TextBackground_5};
        }
    }


`

const SocialMediaDIV = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;



`

// container flex with all right reserved, privacy...etc
const FooterBelowDIV = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.2rem;

    @media (max-width: 904px){
                flex-direction: column;
                justify-content: left;
            }

`
// individual flexbof for privacy, legal mention...etc
const FooterBelowLinkDIV = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & p { font-size:13px; line-height: 15px; margin-left: 2rem; color:white; }

    & hr { color: white !important; width:100%; }

    @media (max-width: 904px){
                margin-left: 1rem;
                margin-top: 1rem;
            }
`

// individual flexbof for privacy, legal mention...etc
const FooterCopyrightDIV = styled.div`
    font-size: 13px;
    line-height: 15px;
    color: white;

    & p{
            text-align: center;
            cursor: pointer;
            &:hover{
                color:${colors.TextBackground_5};
            }
        }
`

const AInsta = styled.a`

    margin-bottom: 1rem;
    &:hover{
        color:#E64763;
    }
`

const ATikTok = styled.a`

    &:hover{
        color:#09F1EC;
    }
`

const AMap = styled.a`
    cursor: pointer;
    &:hover{
        color:${colors.TextBackground_5};
    }
`

const APhone = styled.a`
    cursor: pointer;
    &:hover{
        color:${colors.TextBackground_5};
    }
`

const AMail = styled.a`

    &:hover{
        color:${colors.TextBackground_5};
    }
`

const NavLinkModif = styled(NavLink)`

    & p{
        &:hover{
            color:${colors.TextBackground_5};
        }
    }

`

const AModif = styled.a`

    & p{
        &:hover{
            color:${colors.TextBackground_5};
        }
    }

`

const ImgAnim = styled.img`

    transition: transform 2s;
    transform: rotate(0deg); // État initial

    &:hover {
        transform: rotate(360deg); // État au survol
    }

`

function Footer() {

    return (
        <FlexContainerFooterDiv>

            <FooterLinksDIV>

                {/* 1 */}
                <FooterLinksDIVDIV>

                    <FooterTxtAndLogoDIV>
                    <AMap style={{marginLeft:"0.45rem"}} target="_blank" rel="noreferrer"><i className="fa fa-map-marker fa-2x"></i> </AMap>
                    <p> Montpellier, France </p>
                    </FooterTxtAndLogoDIV>

                    <FooterTxtAndLogoDIV>
                    <APhone target="_blank" rel="noreferrer"><i className="fa fa-phone fa-2x"></i> </APhone>
                    <p>07 78 17 48 54</p>
                    </FooterTxtAndLogoDIV>

                    <FooterTxtAndLogoDIV>
                    <AMail href="mailto:inkbynoux@gmail.com" target="_blank" rel="noreferrer"><i className="fa fa-envelope fa-2x"></i> </AMail>
                    <a style={{color:"white",textDecoration:"none"}} href="mailto:InkByNoux@gmail.com">
                    <p>inkbynoux@gmail.com</p>
                    </a>
                    </FooterTxtAndLogoDIV>
                </FooterLinksDIVDIV>

                {/* 2 */}
                <FooterLinksDIVDIV>
                    <ImgAnim src={'/data/footer/logo.webp'} alt="logo of app" style={{width:"140px", height:"140px", opacity:"0.5", alignSelf:"center"}}/>
                </FooterLinksDIVDIV>

                {/* 3 */}
                <FooterLinksDIVDIV>
                        
                    <SocialMediaDIV>
                       <AInsta href="https://www.instagram.com/noux.tt/" target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram fa-2x"></i> </AInsta>
                       <ATikTok href="https://www.tiktok.com/@inkbynoux34" target="_blank" rel="noreferrer"><i className="fa-brands fa-tiktok fa-2x"></i> </ATikTok>
                    </SocialMediaDIV>

                </FooterLinksDIVDIV>


            </FooterLinksDIV>

            <hr style={{marginBottom:"1rem"}}></hr>

            <FooterBelowDIV>

                <FooterCopyrightDIV>
                    <p> © {new Date().getFullYear()} InkByNoux. All right reserved </p>
                </FooterCopyrightDIV>

                <FooterBelowLinkDIV>

                    <NavLinkModif style={{textDecoration:"none"}} to="/mentions-legales/"> <div> <p>Mentions légales</p></div> </NavLinkModif>
                    <div> <p>-</p></div>
                    <AModif style={{textDecoration:"none"}}  href="https://sebastien-petit-dev.com/" target="_blank"> <div> <p>Site internet réalisé par SebaDev</p></div> </AModif>

                </FooterBelowLinkDIV>

            </FooterBelowDIV>
        

        </FlexContainerFooterDiv>
    )
}
 
export default Footer