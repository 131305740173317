import styled from 'styled-components'
import React from 'react';
import colors from "../../utils/style/color"

import {useState, useRef, useEffect} from 'react'
import { EffectCoverflow, Autoplay} from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import "../../utils/style/swiperResolveBugBuild.css"

// text gestation header
const AnchorTextH1 = styled.h1`

    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    color: white;

    @media(max-width: 904px) {
        font-size: 2rem;
}

`
const TextDIV = styled.div`
    
    padding: 3rem 0;
    @media(max-width: 904px) {
        padding: 3rem 0;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

`
const LineDIV = styled.div`
    display:inline-block;
    vertical-align: middle;
    height:2px;
    width: ${(props) => props.$widthE + "px"};
    background: ${colors.skincolorComplementary};
    content:"";
    margin :0.25rem;

    @media(max-width: 904px) {
        width: 100vw;
}
`
const GlobalContainer = styled.div`

    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    height: 80vh;
`

function RealisationBestSlider({rootData, dataImgGalery}) {

    const ref = useRef(null);
    const [widthE, setWidth] = useState(0);

    //auto width resize
    useEffect(() => {

        // when the component gets mounted
        setWidth(ref.current.offsetWidth);

        // to handle page resize
        const getwidth = () => {
          setWidth(ref.current.offsetWidth);
        };

        window.addEventListener("resize", getwidth);
        // remove the event listener before the component gets unmounted

        return () => window.removeEventListener("resize", getwidth);
      }, []);


    const swiperRef = useRef(null);

    const swiperParams = {
        effect: 'coverflow', 
        centeredSlides:true,
        slidesPerView:'auto',
        speed:2000,
        autoplay:{delay: 200,disableOnInteraction: true},
        coverflowEffect:{
            rotate: 10,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        },
        loop:true,
        allowTouchMove: false, 
        simulateTouch: false, 
        modules:[EffectCoverflow, Autoplay ],
      };


    return ( <div style={{backgroundColor:colors.TextBackground_2}}>

        <TextDIV>
            <div data-aos="fade-right" data-aos-duration = "800" data-aos-offset="20">
                <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <LineDIV $widthE = {widthE}></LineDIV>
                    <AnchorTextH1 ref={ref}>RÉALISATIONS</AnchorTextH1>
                    <LineDIV style={{marginBottom:"1rem"}} $widthE= {widthE}></LineDIV>
                </div>
                <p style={{color: "white", textAlign: "center", fontSize: "1.5rem"}}><b>Sélection de tatouages axées sur le style abstrait</b></p>
            </div>
        </TextDIV>


        <GlobalContainer className="swipperContainer1">

            <Swiper ref={swiperRef} {...swiperParams}>

                {dataImgGalery.map(function(data) {
                    return (

                        <SwiperSlide key={data.id}>
                            <img src={'/data/'+rootData+'/image/'+data.path} alt="meilleurs tatouage"/>
                        </SwiperSlide>
                            )
                })}
            </Swiper>
            
        </GlobalContainer>
    </div>
)}
export default RealisationBestSlider