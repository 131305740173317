import FirstPresentation from "../components/home/FirstPresentation"
import FormFlash from "../components/contact/FormFlash"

function Contact() {

    return <div> 
             
            <FirstPresentation contactbutton={false} bigText={true} presentationImgPath={"/data/home/banner/contact.webp"} text={<div><em>CONTACT & DEVIS</em></div>} text1={"Contactez-moi ou remplissez le formulaire pour toute demande de tatouage"} ></FirstPresentation>

            <FormFlash></FormFlash>
        
        </div>
}

export default Contact