import {useState, useEffect} from 'react'
import { useLocation } from "react-router-dom";
import styled from 'styled-components'
import Checkbox from '@mui/material/Checkbox';
import { grey,orange } from '@mui/material/colors';
import colors from "../../utils/style/color"
import {flashList_noir, flashList_couleur, flashList_2couleur, flashList_3couleur} from '../../data/flashcolor'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ContactBlockIcon from "./ContactBlockIcon"

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const FlexContainerGlobalDIV = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    overflow-x: hidden;

`

const TextFlexContainerDIV = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    flex-direction: column;
    background-color: ${colors.TextBackground_3};
`

const P = styled.p`
    color: white;
    width:50vw;
    text-align: justify;
    font-size: 1.1rem;
    padding: 1rem;

@media (max-width: 904px){
    font-size: 0.9rem;
    width:80vw;
}
`

const LineDIV = styled.div`
    max-width: 904px;
    display:inline-block;
    align-self: center;
    height: 3px;
    width: 50px;
    border-radius: 3px;
    background: ${colors.skincolorComplementary};
    content:"";
    text-align: center;
`

const FormUserFORM = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.TextBackground_2};

    @media (max-width: 904px){
                flex-direction: column;
                align-items: center;
            }
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    color: white;

    @media (max-width: 904px){
        flex-direction: column;
    }

`

const FormStaticDataDIV = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    flex-direction: column;
    width: 45vw;

    @media (min-width: 905px){

    &:hover{
        border: solid 2px ${colors.TextBackground_4bisbis};
        border-radius: 15px;
    }
    }

    @media (max-width: 904px){
        
        border: solid 2px ${colors.TextBackground_4bisbis};
        border-radius: 15px;
        width: 80vw;
    }
`

const FormDynamicDataDIV = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    flex-direction: column;
    width: 45vw;

    @media (min-width: 905px){

    &:hover{
        border: solid 2px ${colors.TextBackground_4bisbis};
        border-radius: 15px;
    }
    }

    @media (max-width: 904px){
        
        border: solid 2px ${colors.TextBackground_4bisbis};
        border-radius: 15px;
        width: 80vw;
    }
`

const PForm = styled.p`
    padding: 1rem;
    margin: 1rem 0 2rem 0;
    background-color: ${colors.TextBackground_4};
    border-radius: 10px;
    border: solid ${colors.skincolor};
    border-width: 2px;
    letter-spacing: 0.05rem;
    font-weight: bold;
    
    &:hover{
        border-width: 3px;
    }

    @media (max-width: 904px){
        padding: 0.75rem;
        margin: 1.5rem;
        text-align: center;
        font-size: 1.3rem;
    }
`

const Label = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    text-align: center;
    font-weight: bold;
    @media (max-width: 904px){
        font-size: 1.25rem;
    }
`

const Input = styled.input`
    padding: 1rem;
    width: 50%;
    border: none;
    border-radius: 10px;
    margin: 0.5rem 0 1.5rem 0;
    color:white;
    background : ${colors.TextBackground_4bisbis};
    font-size: 0.8rem;
    @media (max-width: 904px){
        font-size: 1rem;
    }
`

const Select = styled.select`
padding: 1rem;
color: white;
width: 100%;
border-radius: 10px;
margin: 0.5rem 0 1.5rem 0;
background : ${colors.TextBackground_4bisbis};
border: none;
align-self: center;
font-size: 0.8rem;

@media (max-width: 904px){
        font-size: 1rem;
    }
`

const GlobalSelectForMobile = styled.div`
display:flex;
flex-direction: column;

@media (max-width: 904px){
        flex-direction: column;
        width: 100%;
    }
`

const SelectLeftDiv = styled.div`
    display:flex; 
    flex-direction: column; 

    @media (max-width: 904px){
        margin-left: 1rem;
        margin-right: 1rem;
    }
`

const SelectRightDiv = styled.div`
    display:flex; 
    flex-direction: column; 

    @media (max-width: 904px){
        margin-left: 1rem;
        margin-right: 1rem;
    }
`

const Textarea = styled.textarea`
    resize: none;
    height : 10rem;
    padding: 1em;
    width: 75%;
    color:white;
    border: none;
    border-radius: 15px;
    margin: 0.5em 0 1em 0;
    font-size: 0.8rem;
    background : ${colors.TextBackground_4bisbis};
`

// form submit container
const FormSubmitDIV = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
    margin: 1rem 0rem 2.5rem 0rem;
`

const Button = styled.button`
    border: none;
    color: white;
    cursor:pointer;
    border-radius: 10px;
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    padding: 1.5rem 2rem;
    background-color : rgb(65, 96, 137);
    transition: 0.3s;

    &:hover{
        background-color : ${colors.skincolorComplementary};
    }

`

const ColorCheckDIVMain = styled.div`
    display:  ${(props) => (props.$categorie === 'Noir & Couleur') ?  "flex" : "none"};
    flex-direction: column;
    font-size: 0.9rem;

    @media (max-width: 904px){
        margin-left: 1rem;
        margin-right: 1rem;
    }
`

const ColorCheckDIV = styled.div`
        display:  ${(props) => (props.$categorie === 'Noir & Couleur') ?  "flex" : "none"};
        flex-direction: row;
`
const LabelSpe = styled(Label)`
    display:  ${(props) => (props.$categorie === 'Noir & Couleur') ?  "flex" : "none"};
`

const Span = styled.span`
    display: block;
    width: ${(props) => props.$isMobileVersion ? "50px" : "3px"};
    height: ${(props) => props.$isMobileVersion ? "3px" : "50px"};
    border-radius: 3px;
    background-color: ${colors.skincolorComplementary};
`

const ContactContainer = styled.div`
    background-color: ${colors.TextBackground_1};
    display: flex;
    width:100vw;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;

`

const ContactContainerSub = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 1.5rem;
    color: white;
    width: 100vw;

    @media (max-width: 904px){
        flex-direction: column;
    }

`

const FilePondModif = styled(FilePond)`
.filepond--panel-root { background-color: ${colors.TextBackground_4bisbis}; }

.filepond--drop-label {
    color: white;
}
font-size: 0.8rem;

@media (min-width: 1500px){
        font-size: 1.4rem;
    }

`

const FilepondDiv = styled.div`
    width: 80%;

    @media (max-width: 800px){
        width: 113%;
    }

`


// powered by EmailJS 
function FormFlash() {

    const {state} = useLocation();
    const {categorie, flashPath } = state; // Read values passed on state

    useEffect(() => {
        window.scrollBy(0, 400);
      }, []);

    const [isMobileVersion, setIsMobileVersion] = useState(window.innerWidth < 810);

    useEffect(() => {

        window.addEventListener( 'resize', onWindowResize );

        function onWindowResize() {

            if(window.innerWidth < 810){
                setIsMobileVersion(true)
            }
        
            else{setIsMobileVersion(false)}
          }
          
        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', onWindowResize);}

    }, [isMobileVersion]);

    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [tel, setTel] = useState('');
    const [message, setMessage] = useState('');
    const [couleur, setCouleur] = useState('Noir');
    const [zone, setZone] = useState('Pied');
    const [taille, setTaille] = useState('');

    const [files, setFiles] = useState([])

    const [checked1, setChecked1] = useState(true);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);
    const [checked4, setChecked4] = useState(false);

    const [isLoading, setIsLoading] = useState(false)
    const [emailSendOk, setIsEmailSendOk] = useState(false)
    const [emailSendError, setIsEmailSendError] = useState(false)

    useEffect(() => {
        const loadInitialImage = async () => {
          const response = await fetch('/data/flash/image/' + flashPath);
          if (!response.ok) {
            throw new Error('Image non trouvée ou erreur de chargement');
          }
          const blob = await response.blob();
          console.log(blob.size);
          const file = new File([blob], flashPath, { type: 'image/webp' });
    
          setFiles([{
            source: file,
            options: {
              type: 'local'
            }
          }]);
        };
    
        loadInitialImage();
      }, [flashPath]);

    const sendEmail = async event => {

        setIsLoading(true)

        // to fix issue in firefox browser. pb : time for refreshing from, is refreshing before send it
        event.preventDefault();

        // data gestation
        const formData = new FormData()
        
        // data text group
        formData.append('field1', name)
        formData.append('field2', mail)
        formData.append('field3', tel)
        formData.append('field4', message)
        formData.append('field5', couleur)
        formData.append('field6', zone)
        formData.append('field7', taille)

        /*     const baseUrl = "http://localhost:8081/api/sendEmail"; local server*/
        /*     const prodUrl = "https://inkbynoux-tatoo.com/api/sendEmail"; dev server*/

        // data file group
        files.forEach((obj) => {
            formData.append('imageFile', obj.file); // Ensure that the field name matches the server-side field name
          });

        // send fetch request
        const settings = {
            method: 'POST',
            body: formData,
            }
        
        try{
        const response = await fetch("https://inkbynoux-tatoo.com/api/sendEmail", settings)

        if (response.ok) {
            console.log('Email sent successfully');
            setIsLoading(false)
            setIsEmailSendOk(true)
          } 
        else {
            console.error('Error sending email');
            setIsLoading(false)
            setIsEmailSendError(true)
        }
        }

        catch (error) {
          console.error('Error:', error);
          setIsLoading(false)
          setIsEmailSendError(true)
        }

    }

    // select change select gestation
    const [options, setOptions] = useState(flashList_noir);

    function ChangeOptions(number) {

        if(number===1){
            setOptions(flashList_noir)
        }
        else if(number===2){
            setOptions(flashList_couleur)
        }
        else if(number===3){
            setOptions(flashList_2couleur)
        }
        else if(number===4){
            setOptions(flashList_3couleur)
        }

    };

    


    // select change select icon gestation
    const handleChange1 = (event) => {
        if (checked1 === false){
            setChecked1(event.target.checked);
            setChecked2(!event.target.checked);
            setChecked3(!event.target.checked);
            setChecked4(!event.target.checked);
            ChangeOptions(1)
        }
      };

      const handleChange2 = (event) => {
        if (checked2 === false){
            setChecked1(!event.target.checked);
            setChecked2(event.target.checked);
            setChecked3(!event.target.checked);
            setChecked4(!event.target.checked);
            ChangeOptions(2)
        }
      };

      const handleChange3 = (event) => {
        if (checked3 === false){
            setChecked1(!event.target.checked);
            setChecked2(!event.target.checked);
            setChecked3(event.target.checked);
            setChecked4(!event.target.checked);
            ChangeOptions(3)
        }
      };

      const handleChange4 = (event) => {
        if (checked4 === false){
            setChecked1(!event.target.checked);
            setChecked2(!event.target.checked);
            setChecked3(!event.target.checked);
            setChecked4(event.target.checked);
            ChangeOptions(4)
        }
      };

    return (
        <FlexContainerGlobalDIV>

            <ContactContainer>

                <ContactContainerSub>

                    <ContactBlockIcon imgPath={"/data/contact/AdressLogo.webp"} text={"InkByNoux, Montpellier"} link={"https://www.google.fr/maps/place/Montpellier/@43.6100088,3.8329699,13z/data=!3m1!4b1!4m6!3m5!1s0x12b6af0725dd9db1:0xad8756742894e802!8m2!3d43.610769!4d3.876716!16zL20vMGpxMjc?entry=ttu"}></ContactBlockIcon>
                    <Span $isMobileVersion={isMobileVersion}></Span>
                    <ContactBlockIcon imgPath={"/data/contact/MailLogo.webp"} text={"inkbynoux@gmail.com"} link={"mailto:inkbynoux@gmail.com"}></ContactBlockIcon>
                    <Span $isMobileVersion={isMobileVersion}></Span>
                    <ContactBlockIcon imgPath={"/data/contact/TelLogo.webp"} text={"07 78 17 48 54"}></ContactBlockIcon>
                    <Span $isMobileVersion={isMobileVersion}></Span>
                    <ContactBlockIcon imgPath={"/data/contact/insta.webp"} text={"Instagram"} link={"https://www.instagram.com/noux.tt/"}></ContactBlockIcon>
                    <Span $isMobileVersion={isMobileVersion}></Span>
                    <ContactBlockIcon imgPath={"/data/contact/HoraireLogo.webp"} text={"Du lundi au samedi"}></ContactBlockIcon>

                </ContactContainerSub>

            </ContactContainer>

            <FormUserFORM onSubmit={sendEmail}>

                <p style={{textAlign:"center", margin:"2.5rem 1rem 0 1rem", fontSize: "1.75rem",letterSpacing:"0.15rem", color:"white"}}><b>VOUS AVEZ UN PROJET ?</b></p>
                <p style={{textAlign:"center", margin:"1rem 1rem 2rem 1rem", fontSize: "1.25rem",letterSpacing:"0.1rem", color:"white"}}><b>Rencontrons-nous pour en discuter dans les prochains jours</b></p>

                <FormContainer>

                        <FormStaticDataDIV>

                            <PForm>INFORMATIONS & MESSAGE</PForm>

                            <Label>Nom*</Label>
                            <Input
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder='Ton Nom et Prénom*'
                                type='text'
                                required
                            />
                            

                            <Label>Mail*</Label>
                            <Input
                                name="mail"
                                value={mail}
                                onChange={(e) => setMail(e.target.value)}
                                placeholder='exemple@gmail.com*'
                                type='email'
                                required
                            />
                            

                            <Label>Téléphone*</Label>
                            <Input
                                name="tel"
                                value={tel}
                                onChange={(e) => setTel(e.target.value)}
                                placeholder='0610101010*'
                                type='text'
                                required
                            />

                            <Label>Message*</Label>
                            <Textarea
                                name="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder='Description du projet...*'
                                required
                            ></Textarea>

                        </FormStaticDataDIV>

                        <FormDynamicDataDIV>

                            <PForm>DÉTAILS TATOUAGE</PForm>

                        <FilepondDiv style={{width:"75%", paddingBottom:"1rem"}}>
                            <FilePondModif
                            files={files}
                            acceptedFileTypes={['image/*']}
                            onupdatefiles={setFiles}
                            disabled={true}
                            credits={false}
                            maxFiles={1}
                            name="filepond" 
                            labelIdle='Glisse & Dépose une/des images OU <span class="filepond--label-action"><b>Parcourir...<b></span>'
                            />
                        </FilepondDiv>

                            <GlobalSelectForMobile>

                                <LabelSpe $categorie={categorie} >Type de couleur</LabelSpe>

                                <ColorCheckDIVMain $categorie={categorie} style={{padding:"0.5rem", marginBottom:"2rem", marginTop:"0.5rem", backgroundColor: colors.TextBackground_4bisbis, borderRadius: "10px", color:"white"}}>
                                    <ColorCheckDIV $categorie={categorie}>
                                        <Label style={{fontSize:"1rem"}}>Noir</Label>
                                        <Checkbox label="Noir" checked={checked1} onChange={handleChange1} inputProps={{ 'aria-label': 'controlled' }} sx={{color: grey[600],'&.Mui-checked': {color: grey[800],},}}/>
                                    </ColorCheckDIV>
                                    <ColorCheckDIV $categorie={categorie}>
                                        <Label style={{fontSize:"1rem"}}>Une couleur</Label>
                                        <Checkbox label="Une couleur" checked={checked2} onChange={handleChange2} inputProps={{ 'aria-label': 'controlled' }} sx={{color: orange[400],'&.Mui-checked': {color: orange[600],},}}/>
                                    </ColorCheckDIV>
                                    <ColorCheckDIV $categorie={categorie}>
                                        <Label style={{fontSize:"1rem"}}>Dégrader DOT de deux couleurs</Label>
                                        <Checkbox label="Deux couleurs" checked={checked3} onChange={handleChange3} inputProps={{ 'aria-label': 'controlled' }} sx={{color: orange[600],'&.Mui-checked': {color: orange[800],},}}/>
                                    </ColorCheckDIV>
                                        <ColorCheckDIV $categorie={categorie}>
                                        <Label style={{fontSize:"1rem"}}>Dégrader DOT de trois couleurs</Label>
                                    <Checkbox label="Dégradé de couleurs" checked={checked4} onChange={handleChange4} inputProps={{ 'aria-label': 'controlled' }} sx={{color: orange[800],'&.Mui-checked': {color: orange[900],},}}/>
                                    </ColorCheckDIV>
                                </ColorCheckDIVMain>


                                <SelectLeftDiv>
                                    <Label>Choix de la couleur*</Label>
                                    <Select
                                        name="couleur"
                                        onChange={(e) => setCouleur(e.target.value)}
                                        placeholder='noir'
                                        type='text'
                                        required
                                    > 
                                    {options.map(currentOption => (
                                    <option key={currentOption.id} value={currentOption.value}>
                                    {currentOption.text}
                                    </option>))}
                                    </Select>
                                </SelectLeftDiv>

                                <SelectRightDiv>
                                    <Label>Zone à tatouer*</Label>
                                    <Select
                                        name="zone"
                                        onChange={(e) => setZone(e.target.value)}
                                        placeholder='bras'
                                        type='text'
                                        required
                                    > 
                                    <option value="Pied">Pied</option>
                                    <option value="Mollet">Mollet</option>
                                    <option value="Cuisse">Cuisse</option>
                                    <option value="Doigt">Doigt</option>
                                    <option value="Main">Main</option>
                                    <option value="Avant bras">Avant bras</option>
                                    <option value="Bras">Bras</option>
                                    <option value="Epaule">Epaule</option>
                                    <option value="Manchette">Manchette</option>
                                    <option value="Ventre">Ventre</option>
                                    <option value="Hanche">Hanche</option>
                                    <option value="Côtes">Côtes</option>
                                    <option value="Haut du dos">Haut du dos</option>
                                    <option value="Bas du dos">Bas du dos</option>
                                    <option value="Dos complet">Dos complet</option>
                                    <option value="Torse">Torse</option>
                                    <option value="Cou">Cou</option>
                                    <option value="Crane">Crane</option>
                                    </Select>

                                </SelectRightDiv>

                            </GlobalSelectForMobile>

                            <Label>Taille approximative (en cm)*</Label>
                            <Input
                                name="taille"
                                min={1}
                                max={300}
                                value={taille}
                                onChange={(e) => setTaille(e.target.value)}
                                placeholder='Taille du projet* (cm)'
                                type='number'
                                required
                            />

                        </FormDynamicDataDIV>

                </FormContainer>
                
                <FormSubmitDIV>

                {(!isLoading && !emailSendOk && !emailSendError) && (
                    <Button type="submit">ENVOYER</Button>
                )}
                {isLoading && (
                    <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                )}
                {emailSendOk && (
                    <Button style={{backgroundColor:"rgba(0,255,0,0.1)"}} disabled={true} >MESSAGE ENVOYÉ</Button>
                )}
                {emailSendError && (
                    <Button style={{backgroundColor:"rgba(255,0,0,0.1)"}} disabled={true}>ERREUR</Button>
                )}

                </FormSubmitDIV>

            </FormUserFORM>

            <TextFlexContainerDIV>

                <P style={{marginTop:"2rem", fontSize: "1.75rem",letterSpacing:"0.15rem", textAlign:"center"}}><b>INFORMATION</b></P>
                <LineDIV></LineDIV>
                <P><b style={{color: colors.skincolor}}>FLASH</b> : Onglet "FLASH" ➜ Sélection du tatouage ➜ Remplir formulaire</P>
                <P><b style={{color: colors.skincolor}}>CRÉATIONS PERSONNALISÉES</b> : Envoyez votre idée via le formulaire</P>
                <P><b style={{color: colors.skincolor}}>PRÉPAIEMENT</b> : Acompte demandé pour réservation, déduit du coût total du tatouage</P>
                <P><b style={{color: colors.skincolor}}>ANNULATION</b> : Acompte non remboursable en cas d'annulation pour éviter les désistements tardifs</P>
                <P><b style={{color: colors.skincolor}}>REPORT</b> : Possible de reporter le RDV en cas de problème</P>
                <P><b style={{color: colors.skincolor}}>DESSIN ENVOYÉ 1-2 JOURS AVANT RDV</b> avec ajustements possibles le jour même</P>
                <LineDIV style={{marginBottom:"3rem"}}></LineDIV>

            </TextFlexContainerDIV>

                     
        </FlexContainerGlobalDIV>
)}

export default FormFlash