import styled from 'styled-components'
import colors from "../../utils/style/color"

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    width: 100vw;
    background-color: ${colors.TextBackground_0};

    @media (max-width: 904px)  {
        flex-direction: column;
    }

`

// image container
const ImageDIV = styled.div`
    width: 50%;
    height: 120vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url('/data/home/tatoueurProfile.webp');
    background-size: cover;

    @media (max-width: 904px) {
        width: 100%;
        height: 60vh;
    }



`

// filter container over image container 
const BlackFilterTextDIV = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
    flex-direction: column;
    padding: 2rem 0;
    width: 75%;
    
`

const PresentationFirstP = styled.p`
    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    color: white;
    margin: 1rem 1.25rem;
    padding: 0rem 1.25rem;
`

const PresentationFirstModifP = styled(PresentationFirstP)`
    font-size: 1.75rem;
    text-align: center;
    letter-spacing: 0.05rem;

`

const SecondPresentation = () => {

    return (

        <FlexContainerDIV>

            <BlackFilterTextDIV>
                <PresentationFirstP> <b style={{color: "rgb(203, 159, 126)"}}> ENCRE & CRÉATIVITÉ</b></PresentationFirstP>
                <PresentationFirstModifP><b>Créations <span style={{color: "rgb(203, 159, 126)"}}>personnalisées et flashs</span></b></PresentationFirstModifP>
                <PresentationFirstModifP><b>Intéraction pour élaborer ensemble un <span style={{color: "rgb(203, 159, 126)"}}>tatouage unique</span></b></PresentationFirstModifP>
                <PresentationFirstModifP><b><span style={{color: "rgb(203, 159, 126)"}}>Simulation d'un tatouage sur la peau</span> pour finaliser la taille <span style={{color: "rgb(203, 159, 126)"}}>avant l'encre permanente</span></b></PresentationFirstModifP>
                <PresentationFirstModifP><b>Passionné d'art corporel, prêt à transformer <span style={{color: "rgb(203, 159, 126)"}}>vos idées en réalité</span></b></PresentationFirstModifP>
            </BlackFilterTextDIV>

             <ImageDIV>

             </ImageDIV>



        </FlexContainerDIV>

    )
  }
  
  export default SecondPresentation;