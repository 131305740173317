const colors = {
    
    TextBackground_0 : 'rgb(20, 20, 20)',
    TextBackground_1 : 'rgb(22, 22, 22)',
    TextBackground_2 : 'rgb(24, 24, 24)',
    TextBackground_3 : 'rgb(26, 26, 26)',
    TextBackground_4 : 'rgb(28, 28, 28)',
    TextBackground_4bis : 'rgb(35, 35, 35)',
    TextBackground_4bisbis : 'rgb(45, 45, 45)',
    TextBackground_5 : 'rgb(100, 100, 100)',

    blackFilter_0: 'rgba(32, 32, 32, 0.15)',
    blackFilter_1: 'rgba(32, 32, 32, 0.3)',
    blackFilter_2: 'rgba(32, 32, 32, 0.4)',
    blackFilter_3: 'rgba(32, 32, 32, 0.5)',
    blackFilter_4: 'rgba(32, 32, 32, 0.6)',
    blackFilter_5: 'rgba(32, 32, 32, 0.7)',
    blackFilter_6: 'rgba(32, 32, 32, 0.8)',

    skincolor : 'rgb(203, 159, 126)',
    skincolorComplementary: 'rgb(126, 159, 203)',

}

export default colors