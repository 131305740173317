export const flashList = [
	{
		path: 'flash1.webp',
		categorie: 'Noir',
		id: '1',
	},
	{
		path: 'flash2.webp',
		categorie: 'Noir & Couleur',
		id: '2',
	},
	{
		path: 'flash3.webp',
		categorie: 'Noir & Couleur',
		id: '3',
	},
	{
		path: 'flash4.webp',
		categorie: 'Noir',
		id: '4',
	},
	{
		path: 'flash5.webp',
		categorie: 'Noir & Couleur',
		id: '5',
	},
	{
		path: 'flash6.webp',
		categorie: 'Noir',
		id: '6',
	},
	{
		path: 'flash7.webp',
		categorie: 'Noir & Couleur',
		id: '7',
	},
	{
		path: 'flash8.webp',
		categorie: 'Noir & Couleur',
		id: '8',
	},
	{
		path: 'flash9.webp',
		categorie: 'Noir & Couleur',
		id: '9',
	},
	{
		name: 'Hirondelle',
		path: 'flash10.webp',
		categorie: 'Noir',
		id: '10',
	},
	{
		path: 'flash11.webp',
		categorie: 'Noir',
		id: '11',
	},

]