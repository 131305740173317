import styled from 'styled-components'
import { bool, func } from 'prop-types';

// Hamburger menu style
export const StyledBurger = styled.button`

display: none;

@media(max-width: 904px){
    
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed;
  top: 2.8rem;
  right:1.75rem;
  height: 2rem;
  border: none;
  cursor: pointer;
  z-index: 5;
  

  
  div {
    background-color: aliceblue;
    width: 2rem;
    height: 0.2rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;


    &:first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};

    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
    
  }
}
`

const Burger = ({open, setActive}) => {

  function HandleClick() {
    console.log(open)
    setActive(!open)
    

  }
    return (
      <StyledBurger onClick={() => HandleClick()} open={open}aria-label="navbar mobile version">
        <div/>
        <div/>
        <div/>


      </StyledBurger>
    )
  }

  Burger.propTypes = {
    open: bool.isRequired,
    setActive: func.isRequired,
  };
  
  export default Burger;