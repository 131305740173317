import { createContext, useState } from 'react'

export const ThemeContext = createContext()

export function ThemeProvider({children}) {

    const [open, setActive] = useState(false);

    function toggleValue(){
        setActive(!open)
    }

    const isInstagramBrowser = /instagram/i.test(navigator.userAgent);
    let isMobile = false
    let IsSpecialSocialBrowser = false;

    if (window.innerWidth < 904){
        isMobile = true;
    }

    if((isInstagramBrowser)){
        IsSpecialSocialBrowser = true;
    }
 
    return (
        <ThemeContext.Provider value={{ open, toggleValue, IsSpecialSocialBrowser }}>
            {children}
        </ThemeContext.Provider>
    )
}
