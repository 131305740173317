import styled from 'styled-components'
import colors from "../../utils/style/color"
import {useEffect, useState, useRef} from 'react';
import "../../utils/style/textAnimation.css"
import { NavLink } from 'react-router-dom'

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100vw;
    height: 80vh;

    @media(max-width: 904px) {
        height: 50vh;
    }
`
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    `

// Black filter container
const BlackFilterDIV = styled.div`
    display: flex;
    position: absolute;
    width: 100vw;
    height: 80vh;
    background: ${colors.blackFilter_2};

    @media(max-width: 904px) {
        height: 50vh;
    }
`

// text container
const BlackFilterPlusDIV = styled(NavLink)`
    text-decoration: none;
    color: white;
    display: flex;
    padding: 1.5rem;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: row;
    background-color: ${colors.blackFilter_3};
    backdrop-filter: blur(5px);
    border-radius:75px / 50%; /* Arrondi parfait pour les côtés gauche et droit */
    transition: width 0.25s;

    &:hover{
        border: 1px solid;
        border-color: ${colors.skincolor};
    }

    @media(max-width: 904px) {
        max-width: 80%;
        flex-direction: column;
    }
`

const PresentationFirstH1 = styled.h1`
    text-align: center;
    font-size: 3rem;
    letter-spacing: 0.1rem;
    color: #8e8e8e;
    padding-bottom: 0.75rem;

    @media(max-width: 904px) {
        font-size: 2rem;
        padding-top: 1rem;
    }
`

const PresentationFirstP =styled.p`
    text-align: center;
    font-size: 1.5rem;
    font-weight:bold;
    letter-spacing: 0.05rem;
    color: white;
    padding-bottom: 0.75rem;

`

const DivCircle = styled.div`
    border-radius: 50%;
    border: none;
    height: 55px;
    width: 55px;
    background-color: #adadad;

`

const DIVBookingText = styled.div`

    margin-right:${(props) => props.$isHover ? "1rem" : "1.5rem"};
    transition: all 0.5s;

    @media(max-width: 904px) {
        margin-right:1rem;
    }

`

const DIVPresText = styled.div`

    margin-right:${(props) => (props.$isHover && props.$contactbutton) ? "6rem" : "4rem"};
    transition: all 0.5s;

    @media(max-width: 904px) {
        margin-right:0;
        margin-bottom: 1rem;
    }

`


const FirstPresentation = ({contactbutton, presentationImgPath, text, text1}) => {

    const ref = useRef(null);
    const [didLoad, setDidLoad] = useState(false);

    const windowheight = window.innerHeight;
    const windowwidth = window.innerWidth;

    const [isHover, setIsHover] = useState(false);
    
    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    // text effect
    useEffect(() => {

        if((!didLoad))
            
            {
                const h1Element = ref.current;
                const h1Element_text = h1Element.textContent
                const split_text = h1Element_text.split("");
                h1Element.textContent ="";
        
                for(let i=0; i< split_text.length; i++){
                    h1Element.innerHTML += "<span>" + split_text[i] + "<span>";
                }

                let char = 0;
                let timer = setInterval(onTick, 10);
            
            
                function onTick(){
                    const span = h1Element.querySelectorAll('span')[char];
                    span.classList.add('fade');
                    char++
                    if(char === split_text.length*2){
                        complete();
                        return;
                    }
                }

                function complete(){
                    clearInterval(timer);
                    timer = null;
                }

                setDidLoad(true);
            }
    }, [didLoad])

    return (

        <FlexContainerDIV>

            <Img src={`${presentationImgPath}`} alt=''/>

            <BlackFilterDIV></BlackFilterDIV>

            <BlackFilterPlusDIV onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} $windowheight={windowheight} $windowwidth={windowwidth} to="/contact/">

                <DIVPresText $contactbutton={contactbutton} $isHover={isHover}>
                    <PresentationFirstH1 ref={ref}>{text}</PresentationFirstH1>
                    <PresentationFirstP>{text1}</PresentationFirstP>
                </DIVPresText>

                {(contactbutton) &&

                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                        <DIVBookingText $isHover={isHover}>
                            <p style={{fontSize:"1.25rem", letterSpacing:"0.1rem"}}>Réservation</p>
                        </DIVBookingText>

                        <DivCircle>
                                <img src={'/data/home/arrowAlone.webp'} alt="allez vers contact" style={{width:"55px", height:"55px"}}></img>
                        </DivCircle>
                    </div>
                }

            </BlackFilterPlusDIV>

        </FlexContainerDIV>

    )
  }
  
  export default FirstPresentation;