import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Flash from './pages/Flash'
import PersonalProject from './pages/PersonalProject'
import Info from './pages/Info'
import Contact from './pages/Contact'
import ContactFlash from './pages/ContactFlash'
import Header from './components/header/Header'
import Error from './pages/Error'
import MentionsLegales from './pages/MentionsLegales'
import GlobalStyle from './utils/style/GlobalStyle'
import Footer from "./components/footer/Footer"
import {ThemeProvider} from "./utils/context/ThemeProvider"
import ScrollToTop from "./utils/utils_function/scrollToTop";

// LOGIC PART

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
/*   <React.StrictMode> */
    <Router>

    <ScrollToTop/>

    <ThemeProvider>

      <GlobalStyle/>
      
       <Header/>

        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/flash" element={<Flash/>} />
            <Route path="/projetperso" element={<PersonalProject/>} />
            <Route path="/info" element={<Info/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/contact/flash" element={<ContactFlash/>} />
            <Route path="/mentions-legales" element={<MentionsLegales/>} />
            <Route path="*" element={<Error />} />
        </Routes>

      <Footer/> 

    </ThemeProvider>

    </Router>
/*   </React.StrictMode> */
)