import styled from 'styled-components'
import React from 'react';
import colors from "../../utils/style/color"
import {useRef} from 'react'

import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// import required modules
import { Pagination, Autoplay} from 'swiper/modules';

import "../../utils/style/swiperResolveBugBuildMobile.css"

// text gestation header
const AnchorTextH1 = styled.h1`
    color: white;
    text-align: center;
    font-size: 2rem;

`

const MobileVersionTextDIV = styled.div`

    padding: 3rem 0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        
`

const LineDIV = styled.div`

  display:inline-block;
  vertical-align: middle;
  height:2px;
  width: 100vw;
  background: ${colors.skincolorComplementary};
  content:"";
  margin :0.25rem;
  
`

function RealisationBestSliderMobile({rootData, dataImgGalery}) {

    const ref = useRef(null);

    const swiperRef = useRef(null);
    const originalSpeed = 1500;

    const swiperParams = {
        autoplay: {delay: 800,disableOnInteraction: true}, 
        loop:true,
        speed:originalSpeed, 
        pagination:{dynamicBullets: true,}, 
        modules:[Pagination, Autoplay],
         centeredSlides:true,
         slidesPerView:'auto',
         allowTouchMove: false, 
         simulateTouch: false, 

    };

    return ( <div style={{backgroundColor:colors.TextBackground_2}}>

        <MobileVersionTextDIV>
          <div data-aos="fade-right" data-aos-duration = "800" data-aos-offset="20">
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
              <LineDIV></LineDIV>
              <AnchorTextH1 ref={ref}>RÉALISATIONS</AnchorTextH1>
              <LineDIV style={{marginBottom:"1rem"}}></LineDIV>
            </div>
            <p style={{color: "white", textAlign: "center", fontSize: "1.5rem", padding:"0rem 2rem"}}><b>Sélection de tatouages axées sur le style abstrait</b></p>
          </div>
        </MobileVersionTextDIV>


        <Swiper className="swipperContainer2" ref={swiperRef} {...swiperParams}>
            {dataImgGalery.map(function(data) {
                return (
                    
                    <SwiperSlide key={data.id + "mobile"}>
                        <img src={'/data/'+rootData+'/image/'+data.path} alt="meilleurs tatouage"/>
                    </SwiperSlide>
                        )
            })}

        </Swiper>

        </div>
)}

export default RealisationBestSliderMobile