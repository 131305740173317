import FirstPresentation from "../components/home/FirstPresentation"
import styled from 'styled-components'
import Protocole from "../components/info/Protocole"
import ArrowAnimation from "../components/flashAndPersonalProject/ArrowAnimation"
import colors from "../utils/style/color"

// Global flex container background
const FlexContainerDIV = styled.div`
    display: flex;
    overflow:hidden;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    position:relative;
    background-color: ${colors.TextBackground_2};

`

function Info() {

    const FirstBubbleList = ['Venez avec la peau propre.', 'Ne venez pas le ventre vide 😉', 'Prévoir une tenue adéquate pour les zones dénudées.'] 

    const SecondBubbleList = ['Se laver les mains avant ou passer du gel hydroalcoolique au moment ou vous toucher le tatouage.', 'Laver la zone du tatouage une fois par jour avec un savon au PH neutre.', 'A chaque lavage, passer de la Biseptine en spray, laisser sécher 1H.', "Laisser à l'air libre si possible.","PEAU NORMALE : laisser sans crème durant 2/3 jours.", "PEAU SECHE : laisser sans crème 1/2 jours selon le ressenti."] 

    const ThirdBubbleList = ['Vous pouvez mettre de la crème 1 à 2 fois par jour en couche très fine, faire pénétrer la crème.', 'Continuer de laver le tatouage une seule fois par jour avec un savon au PH neutre.', "La Biseptine n'est plus nécessaire sauf exception."] 

    const FourBubbleList = ['Pendant les 3 semaines de cicatrisation, les bains, la mer et la piscine sont interdits.', 'Egallement est interdit le sport (transpiration), sauna/hammam, jardinage, attention aux poils des animaux.', "Eviter l'exposition directe au soleil durant 3 semaines, ensuite bien protéger avec de l'écran total."]

    const FiveBubbleList = ["Les tatouages s'entretiennent à vie.", 'Préserver le tatouage du soleil en passant de la crème solaire indice maximum.', 'Tout les jours, vous pouvez passer quotidiennement de la crème hydratante matin et/ou soir.'] 

    return <div> 

                <FirstPresentation contactbutton={true} bigText={false} presentationImgPath={'/data/home/banner/info.webp'} text={<div><em>HYGIÈNE ET SOINS</em></div>}  text1={"Consignes et recommandations pour la séance de tatouage"} ></FirstPresentation>

            <FlexContainerDIV>

                <ArrowAnimation text = "CONSIGNES ET SOINS" backgroundcolor={colors.TextBackground_2} textEnable={true}></ArrowAnimation>
                <div>
                <Protocole HeaderTextContainer={'Le jour J...🕛'} BubbleListData={FirstBubbleList} textEnable={false}></Protocole>
                </div>

                <div>
                <Protocole HeaderTextContainer={"Les 2/3 jours d'après...🕑"} BubbleListData={SecondBubbleList}></Protocole>
                </div>

                <div>
                <Protocole HeaderTextContainer={"Les 15 jours suivants...🕕"} BubbleListData={ThirdBubbleList}></Protocole>
                </div>

                <div>
                <Protocole HeaderTextContainer={"Les impératifs...🕘"} BubbleListData={FourBubbleList}></Protocole>
                </div>

                <div>
                <Protocole HeaderTextContainer={"Et après...🕛"} BubbleListData={FiveBubbleList}></Protocole>
                </div>

            </FlexContainerDIV>

            </div>
}

export default Info
