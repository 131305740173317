import styled from 'styled-components'
import BlockPresentationIconTatoo from "../blockIcon/BlockPresentationIconTatoo"
import colors from '../../utils/style/color'

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    width: 100vw;
    background-color: ${colors.TextBackground_1};
    
    @media (max-width: 904px){
        flex-direction: column;
        height: auto;
    }

    @media(min-width: 904px) and (max-width : 1200px) and (min-height: 904px) and (max-height : 1200px)   {
        flex-direction: column;
        height: auto;
    }
`
// use aos package
const BlockPresentationIcon = () => {

    return (

        <FlexContainerDIV>
            <BlockPresentationIconTatoo></BlockPresentationIconTatoo>
        </FlexContainerDIV>

    )
  }
  
  export default BlockPresentationIcon;