export const personalProject = [
	{
		path: '1.webp',
		id: '1',
	},
	{
		path: '2.webp',
		id: '2',
	},
	{
		path: '3.webp',
		id: '3',
	},
	{
		path: '4.webp',
		id: '4',
	},
	{
		path: '5.webp',
		id: '5',
	},
	{
		path: '6.webp',
		id: '6',
	},
	{
		path: '7.webp',
		id: '7',

	},
	{
		path: '8.webp',
		id: '8',
	},
	{
		path: '9.webp',
		id: '9',
	},
	{
		path: '10.webp',
		id: '10',
	},
	{
		path: '11.webp',
		id: '11',
	},
	{
		path: '12.webp',
		id: '12',
	},
	{
		path: '13.webp',
		id: '13',
	},
	{
		path: '14.webp',
		id: '14',
	},
	{
		path: '15.webp',
		id: '15',
	},
	{
		path: '16.webp',
		id: '16',
	},
	{
		path: '17.webp',
		id: '17',
	},
	{
		path: '18.webp',
		id: '18',
	},
	{
		path: '19.webp',
		id: '19',
	}
]
