import FirstPresentation from "../components/home/FirstPresentation"
import ArrowAnimation from "../components/flashAndPersonalProject/ArrowAnimation"
import ImagesGallery from "../components/flashAndPersonalProject/ImagesGallery"
import {flashList} from "../data/flashData"
import colors from "../utils/style/color"

function Flash() {

    return <div> 
        
        <FirstPresentation contactbutton={true} bigText={true} presentationImgPath={"/data/home/banner/flash.webp"} text={<div><em>FLASH</em></div>} text1={"Des tatouages uniques et personnalisés"}></FirstPresentation>

        <ArrowAnimation text = "SÉLECTIONNE UN FLASH" backgroundcolor={colors.TextBackground_1} textEnable={true}></ArrowAnimation>

        <ImagesGallery clickGoForm={true} sizeModif={true} enableViewer={false} enableOverllay={true} rootData='flash' DataImgGalery={flashList} withBackgroundColor={true} ></ImagesGallery>

        </div>
}

export default Flash
