import FirstPresentation from "../components/home/FirstPresentation"

function Error() {

    return (

        <div>
        
            <FirstPresentation bigText={true} presentationImgPath={"/data/home/banner/404.webp"} text={<div><em> Erreur 404...🤨</em></div>}></FirstPresentation>

        </div>
    )
    }
 
export default Error